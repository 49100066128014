import React from 'react'
import './layout.css'

export default function layout(props) {
    return (
        <div className="section-layout">
            {
                props.children
            }
        </div>
    )
}
