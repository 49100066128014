import React, {Fragment} from 'react';

import Landing from './screens/landing'
import Services from './screens/services'
import ContactUs from './screens/contactus'
import Layout from './screens/layout'
import Navbar from './screens/common/navbar'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Navbar/>
      <Layout>
        <Switch>
          <Route exact path="/">
            <Fragment>
              <Landing />
              <Services />
            </Fragment>
          </Route>
          <Route exact path="/contactus">
            <ContactUs />
          </Route>
          <Route exact path="/healthyhabitat" render={() => (window.location = "https://karinaguevaraancor.wixsite.com/healthyhabitat")} />
        </Switch>
      </ Layout>
    </Router>
  );
}

export default App;