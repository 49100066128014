import React from 'react'
import './advisorMini.css'
import './advisorMiniNoPhoto.css'

export default function advisorMiniNoPhoto(props) {
    const {selected,id} = props
    const classname = `advisor-mini-container ${selected === id?'selected':''}`;

    return (
        <div className={classname} onClick={(params) => {
            props.onClick(props.id)   
        }}>
            <div className='mini-adisor-name-container-no-photo'>
                <p className='advisor-mini-text-1'>{props.name}</p>
                <p className='advisor-mini-text-2'>{props.lastName}</p>
            </div>
        </div>
    )
}
