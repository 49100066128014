import React, { Component } from 'react'
import './landing.css' 
import partnerIcon from './../../resources/icons/partner.png'
import hfgIcon from './../../resources/icons/HFG.png'
import SinglePhoto from './common/SinglePhoto'
import SingleAdvisor from './common/singleAdvisor'
import SingleExecutive from './common/singleExecutive'
import SingleAdivsorNoPhoto from './common/singleAdvisorNoPhoto'
import GRLogo from './../../resources/images/gr-logo.png'
import Section2Img from './../../resources/images/section-2-img.jpg'
import NuriLogo from './../../resources/images/nuricumbo-logo.png'
import RoadLogo from './../../resources/images/road-logo.png'
import DiestroLogo from './../../resources/images/diestrologoWhite.png'
import AdvisorMini from './common/advisorMini'
import AdvisorMiniNoPhoto from './common/advisorMiniNoPhoto'
import Popover from '@material-ui/core/Popover';
import ReactModal from 'react-modal';
import ClosePopUp from './../../resources/images/popupclose.png'
import BtnPopUp from './../../resources/images/btnpopupcovid.png'
import CovidPdf from './../../resources/files/CovidCatalogue.pdf';

export default class landing extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedAdvisor: 0,
            selectedTeam: 0,
            team:[{
                   id:0,
                   name:'Luis Daniel Meraz Rosales',
                   position:'Executive Vice', 
                   secondPosition:'President & Director of Business Strategy',
                   description:'A Doctor of Medicine (U of G) with a Specialty in Clinical Pathology (IMSS-CMNSXXI / UNAM-2003), Luis Daniel Meraz Rosales served as Head of the Division of Diagnostic and Treatment Auxiliary Services of the Old Civil Hospital of Guadalajara. After seeing success in his field, he moved up to a position as General Director of Planning and President (alternate secretary) in the procurement commission of the Health Services of the State of Jalisco, Secretary of Health of the State of Jalisco (2013-17).',
                   extraDescription:'Now, he serves as Director of Business Strategy at ANCORP Capital Group where his extensive experience helps ACG strategize not only within the company but also for its many clients. As well as his extensive medical industry and business experience, Luis Daniel Meraz Rosales is  also a long-serving professor at the Universidad de Guadalajara, teaching Microbiological Medicine.',
                   additionalDescription:'',
                   photo:''
                },{
                    id:1,
                    name:'Pierre Galoppi',
                    position:'Vice President,', 
                    secondPosition:'Investor Relations',
                    description:'With over 25 years of experience doing business in Latin America, Mr. Galoppi is the President of 1st PMG Capital Corporation, a company dedicated to providing consulting services in the areas of fundraising, mergers & acquisitions and financial services, which Mr. Galoppi founded in 2006. Mr. Galoppi has also served as founder and President of PWG Trading Corporation, a company which started, developed and operated a network of 18 specialized travel offices. In addition, Mr. Galoppi served as the Chief Operating Officer and Chief Financial Officer of Gulfstream International Airlines, the regional airline for United (UA) and Continental (CO) throughout the state of Florida and the Bahamas. ',
                    extraDescription:'Mr. Galoppi has extensive operating and business management experience and holds both a B.Com. in Finance and an MBA from Concordia University in Montreal. Mr. Galoppi is fluent in five languages including English, Spanish, Italian, French and Portuguese.',
                    additionalDescription:'',
                    photo:''
                },{
                    id:2,
                    name:'Gerry Pascale',
                    position:'Chief Financial',
                    secondPosition:'Officer', 
                    description:'In this role, in addition to his responsibilities in ACG, he also works closely with clients throughout the process of raising capital and becoming a publicly listed company in the US and Canada. He has detailed experience analyzing business plans, reviewing financial statement preparation, preparing financial projections and developing valuation models in order to advise clients throughout the process of public and private equity transactions as well as mergers and acquisitions and corporate restructurings. His responsibilities include financial modeling, managing the audit process and developing and training a staff to manage accounting, reporting, treasury and audit functions.  He has worked as a financial analyst with Intel Corporation, Emerson Electric and the Corporate Executive Board. ',
                    extraDescription:'Gerry completed his MBA at the University of Chicago in 1998.  He earned a B.S. degree in Accounting from Virginia Tech University in 1992.',
                    additionalDescription:'',
                    photo:''
                },{
                    id:3,
                    name:'Miguel Angel Rodriguez',
                    position:'Comercial Vice',
                    secondPosition:'President', 
                    description:'Specialist in International Trade and International Economic Relations. He has directed commercial teams for the discussion of International Financing and Credits with government entities, Banks and Export Credit Insurance organizations (COFACE (France), CESCE (Spain), EDC (Canada).',
                    extraDescription:'He has visited commercial missions: Europe: Russia, Czech Republic, Poland, Romania, Hungary, Bulgaria, Germany, France, Holland, Belgium, Switzerland, Spain, Great Britain. Far East: China, Vietnam, Thailand. America: Canada, Mexico, Argentina, Uruguay, Ecuador, Colombia, Peru, Brazil. Spain. Director of Exports, Empresa Fanegas S.A. Buenos Aires, Argentina (1999-2001. General Manager Company AMS Foods International SA, Buenos Aires, Argentina (2001-2010). Export Director Company South Agribusiness SA, Buenos Aires, Argentina (2010-2016). General Director Main Consulting Corp, Miami, FL (to date).',
                    additionalDescription:'Graduate of Economics in the Foreign Trade specialty, University of Havana, Cuba, in 1980. Post Degree in International Economic Relations and International Maritime Transport, Ministry of Foreign Trade, Havana, Cuba. Graduated from the International Cereals Course of Canada, Canadian Grain Institute, Winnipeg, Manitoba, Canada (1978). International Course on Management of Cereals Importing Companies, UNCTAD, Oslo, Norway (1982).',
                    photo:''
                },{
                    id:4,
                    name:'Jose Jaime Diaz Gonzalez de la Campa',
                    position:'Vice President',
                    secondPosition:'Business Development, Mexico',
                    description:'He is an experienced consultant for multinational companies working in Mexico and the United States and has led teams in Latin America, as well as Mexican private companies. He opened the operations of INTL FCSTONE (Fortune 500) in Mexico. He has lectured in Mexico and the United States and written articles for the Financial, the Universal, the IMEF magazine published by Expansion and for IAFEI (Switzerland). He is a Certified Consultant by FIRA and has FIGURE 3 of the AMIB as an Investment Strategies Advisor, specialized in the area of ​​Corporate Finance, restructuring of companies, institutionalization and transition design of family businesses, and strategic planning. In addition to risk management and funding strategies, where he had led a team analyzing financial markets and derivative instruments. ',
                    extraDescription:'Graduated from the bachelor’s in business administration from the Universidad Iberoamericana with a Specialty in Finance. He holds a master’s degree specializing in International Business from the University of Miami. He also has a specialty in Project Management from the Stevens Institute of Technology in New Jersey',
                    additionalDescription:'',
                    photo:''
                },], 
            advisors:[{
                id: 0, 
                name: 'George' , 
                lastName: 'Diamond',
                description:'Mr. Diamond got his start practicing securities law in Dallas, Texas. Working under both Godwin, Carlton & Maxwell, PC and Gray Reed & McGraw he represented corporate and individual clients in various corporate and securities transactions from 1991 to 1997. Moving on to become a Partner/Of Counsel in he Dallas, Texas office of Jackson Walker L.L.P, he represented many public and privately held businesses on a range of corporate matters including M&A Activities, Asset Acquisitions, etc.',
                extraDescription:'From 2005 and onward he has served as a Principal and General Counsel for Halter Financial Group where he consults on cross-border M&A and financing transactions as well as advises clients of HFG on preparation of business plans and offering material preparation services such as NASDAQ and NYSE listing applications, transaction structuring, among others.',
                photo:''
            },{
                id: 1, 
                name: 'David R.' , 
                lastName: 'Earhart',
                description:'With nearly 30 years of experience focused on structuring extremely sophisticated, high-value transactions and securities offerings, David Earhart knows what it takes to help clients expand their businesses, raise capital and go public. Leader of the Capital Markets and Securities Practice Group, his practice covers all areas of corporate, transactional and securities law, everything including complex mergers, acquisitions and dispositions. David also represents clients in project finance transactions and has handled numerous private equity and debt financing deals.',
                extraDescription:'David’s clients include publicly traded companies, mid-size businesses and startups across every industry that rely on his skillful guidance on much more than strategic transactions and securities offerings. David also serves as outside general counsel for many of his clients, advising on the entire range of operational, governance and day-to-day matters.',
                photo:''
            },{
                id: 2, 
                name: 'Rodrigo' , 
                lastName: 'Llanos',
                description:'Has occupied leading roles in some of the most recognized financial institutions in Mexico for 40 years, since 2002 has been devoted to implementing US subnational investing and financing models in Mexican states and municipalities as well as in Vietnam provinces, through programs supported by USAID and IADB, and promoting private equity investments in Mexican firms. He is also a certified Investment Strategies Advisor by the Mexican Association of Brokerage Firms (AMIB). For over 15 years, he has been a Board Member of industrial and real estate firms with operations and holdings in several States of Mexico. He’s also received accolades as Kentucky Colonel, Arkansas Traveler and Louisiana Honorary Treasurer by the mentioned US States.',
                extraDescription:'Holds a MBA with major in Finance and bachelor’s degree in business, both from La Salle University in Mexico City. From 2015 Board Member of Conservation International Chapter Mexico, from 2017 Board Member of La Salle University Business School, and from 2018 Member of the Governing Board of La Salle University Campus Morelia.',
                photo:''
            },{
                id: 3, 
                name: 'Armando' , 
                lastName: 'Nuricumbo',
                description:'Managing Partner of Nuricumbo + Partners. His career started as Audit Manager with PWC Mexico. After a few years, he moved to the advertising industry where he held Internal Audit positions with Y&R and IPG, two international advertising groups, working in New York City for five years. He returned to Mexico as CFO for the New Business division of McCann Group Worldwide. Today, Nuricumbo + Partners is specialized on subjects such as financial due diligence for Mergers & Acquisitions, special audits, independent process assessments, debt/equity solutions, on-demand financial talent, anti-corruption initiatives, enterprise risk management, and corporate governance. Nuricumbo + Partners  has become a trusted business advisor for companies of all sizes, providing direct support to CEOs and CFOs in special or confidential projects.',
                extraDescription:'Armando graduated as a public accountant, Summa Cum Laude, from the National University of Mexico (UNAM). He is certified in Mexico by the Mexican Institute for Public Accountants and in the U.S. by the Illinois Accountancy Board. He has a master’s degree in Asian Studies by the University of Tamkang in Taiwan.',
                photo:''
            },{
                id: 4, 
                name: 'Luis Gabriel' , 
                lastName: 'M. Marquez',
                description:'Financial executive, he was Leader in Bancomext for more than 20 years in the development and implementation of different Institutional projects and financial impulse to the state, regional and national exporting community. Coordinator of monitoring and rescue projects to the financial portfolio in the Development and Commercial Bank. With high specialization and experience in the implementation of Corporate Governance models in family businesses through the construction of models and organizations linked to digital platforms for efficient and profitable management. He has a business partner in the development of intelligent platforms for financial, commercial and legal management through the company STARTUP 4040apps.com',
                extraDescription:'Graduate of Economics from the University of Guadalajara, with a Master\'s Degree in Banking and Financial Market, from the University of Mexico and studies of Fintech programs at the University of Oxford.',
                photo:''
            }], 
            group:[{text:'Your experience with Gray Reed will be fully aligned with your goals and objectives and tailored specifically to how you want to do business with us. Our lawyers are adaptable and nimble – you will never be forced into a box that doesn’t fit your opportunity or problem, or your needs and desires. This includes how we charge for our services. We work collaboratively with our clients to find rates that are fair and appropriate to the situation whether that is through a standard billable hour or an alternative fee arrangement. We are always happy to propose billing arrangements that we think might work well for you and hear your ideas, too',link:'https://www.grayreed.com/Our-Firm/Overview '},
            {text:'Roadmap Advisors is a specialty mergers and acquisitions firm serving qualified family office, private equity, corporate and individual buyers looking to invest in privately-owned businesses. Unlike many investment banks and deal brokers, we primarily work on acquisition searches. Our suite of services helps successful businesses grow through proprietary transactions and helps investors identify unique opportunities. Our deals are made through proprietary, highly curated, one-to-one introductions between serious buyers and motivated sellers.', link:'http://roadmapadvisors.com/ '},
            {text:'Based in Experience, Teamwork and Flexibility, our consultants have the right level of experience (20+ years) on prior Senior Management positions in all sorts of organizations. Supported by our multidisciplinary set of project leaders, our team includes experts in accounting, tax, risk, internal control, logistics and operations. Based in highly flexible services solutions, you can start slowly with one month of services and keep renewing as long as you need us. You can increase or decrease our involvement as required by the project.', link:'http://www.nuricumbo.com.mx/index.php'},
            {text:'We are a full-service Consulting and Management firm, and we represent true links between our clients and funding sources. We have experience in buying and selling companies in a wide range of sectors such as: pharmaceutical, services, manufacturing, technology and telecommunications, transportation, wholesale and retail, among others. We have the ability to detect investment opportunities both local as well as international, as well as the experience to support throughout these processes to our clients, to achieve inorganic growth objective, vertical or horizontal and its geographical diversification. We analyze customer needs and provide strategic advice, value-added ideas and we manage solutions, thus overcoming your expectations.', link:'http://www.diestro.com/'}],
            groupPopOverOpen: null,
            selectedGroup: 0,
            currentSlider: 0,
            showModal: true
        };
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    getCurrentAdvisor = (params) => {
        return this.state.advisors[this.state.selectedAdvisor]
    }
    changeAdvisor = (id) => {
        this.setState({selectedAdvisor:id})
    }

    getCurrentTeam = (params) => {
        return this.state.team[this.state.selectedTeam]
    }
    changeTeam = (id) => {
        this.setState({selectedTeam:id})
    }
    
    popOverState = (params) => {
        this.setState({groupPopOverOpen:null})
    }

    popOverClose = (params) => {

    }
    
    popOverOpen = (event, index) => {
        this.setState({groupPopOverOpen:event.currentTarget,selectedGroup:index})
    }

    sliderUp = (params) => {
        this.setState({currentSlider:(this.state.currentSlider+1)%4})
    }

    sliderDown = (params) => {
        this.setState({currentSlider:(this.state.currentSlider-1)%4})
    }

    handleOpenModal () {
        this.setState({ showModal: true });
    }
    
    handleCloseModal () {
        this.setState({ showModal: false });
    }

    openPDF() {
        window.open(CovidPdf);
    }
    
    render() {
        var {team,advisors,groupPopOverOpen} = this.state;
        var currentAdvisor = this.getCurrentAdvisor();
        var currentTeam = this.getCurrentTeam();

        return (
            <>
            <div className="container" id='carousel'>
                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                        <li data-target="#myCarousel" data-slide-to="1"></li>
                        <li data-target="#myCarousel" data-slide-to="2"></li>
                        <li data-target="#myCarousel" data-slide-to="3"></li>
                        <li data-target="#myCarousel" data-slide-to="4"></li>
                    </ol>

                    <div className="carousel-inner">
                        <div className="item item-carousell active">
                            <div className="flex-transform item-carousell-1">
                                <div className="carousel-text-container-2">
                                    <h3 className="carousel-header">Financial Solutions</h3>
                                    <span className="carousel-text-2">ACG develops a customized solution for each client.</span>
                                </div>
                            </div>
                        </div>
                        <div className="item item-carousell">
                            <div className="flex-transform item-carousell-2">
                                <div className="carousel-text-container-2">
                                    <a href="#mergeracquisitions">
                                        <h3 className="carousel-header">Merge &amp; Acquisitions</h3>
                                        <span className="carousel-text-2">Make lasting conections while expanding your bussiness.</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="item item-carousell ">
                            <div className="flex-transform item-carousell-3">
                                <div className="carousel-text-container-3">
                                    <a href="#goingpublic">
                                        <h3 className="carousel-header">Going Public</h3>
                                        <span className="carousel-text-2">Discover new sources of capital and expand your investor network.</span>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="item item-carousell ">
                            <div className="flex-transform item-carousell-4">
                                <div className="carousel-text-container-4">
                                    <h3 className="carousel-header">Supply</h3>
                                    <h3 className="carousel-header">Chain</h3>
                                    <span className="carousel-text-2">You and your clients should‘t have to wait fot your funding to come in.</span>
                                </div>
                            </div>
                        </div>
                        <div className="item item-carousell ">
                            <div className="flex-transform item-carousell-5">
                                <div className="carousel-text-container-5">
                                    <h3 className="carousel-header">Exporter</h3>
                                    <span className="carousel-text-2">Never worry about making sure your products react your clients.</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                        <span className="glyphicon glyphicon-chevron-left"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="right carousel-control" href="#myCarousel" data-slide="next">
                        <span className="glyphicon glyphicon-chevron-right"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>

            <div className='section-2' id='about'>
                    <div className='section-2-left'>
                        <div className='section-2-inner-container' >
                            <div className='section-2-header-container' data-aos="fade-right" >
                                <h1 className='section-2-header'>About Us</h1>
                                <h2 className='section-2-second-header'>The Company</h2>
                            </div>
                            
                            <h3 className='section-2-subheader' data-aos="fade-right" >Mission:</h3>
                            <p className='section-2-text-container' data-aos="fade-right" >
                                Our mission is to marry the financial power and global reach of the US Stock Exchanges with the limitless potential for growth found within the countries of South and Central America. Our aim is to help bring South and Central America onto the world’s trading stage.
                            </p>
                            <h3 className='section-2-subheader' data-aos="fade-right">Vision:</h3>
                            <p className='section-2-text-container' data-aos="fade-right">
                            ACG’s vision of the future contains a Latin America that has achieved its massive economic potential and become a globally recognized stage for trading
                            </p>
                        </div>
                    </div>
                    <div className='section-2-right'>
                        <img src={Section2Img} alt="" className='section-2-image'  data-aos="fade-left" />
                    </div>
            </div>
            <div className='section-3' id='ourroots'>
                <h1 className='section-3-header'>Our Roots</h1>
                <p className='section-3-text-container'>
                    Ancorp Capital Group (ACG) is an American finance corporation that offers Strategic Business Advice and tailor-made solutions to the client company. Our strategic advice covers a full range of services such as Financial Solutions, Business Analysis, Strategic Business Planning, Management, Sales Agency, Marketing, Brand Development, Advertisement and specialized Legal Processes among others.
                </p>
                <p className='section-3-text-container'>
                    ACG develops a personalized solution for each client, takes leadership on the development of the project and represents the clients’ interests during the different steps of the process by identifying and securing the right strategies and partners.
                </p>
                <p className='section-3-text-container'>
                    ACG has assembled an expert team, led by specialized companies and individuals, that together combine to provide unique and tailored solutions to clients as demonstrated by its overall success rate
                </p>
            </div>
            <div className='section-4' id='leadership'>
                <div className='section-4-left'>
                    <div className='section-4-inner-left'>
                        <h1 className='section-4-header' data-aos="fade-left">Leadership</h1>
                        <p className='section-4-text' data-aos="fade-left">
                            Highly motivated, results oriented, Professional with superior communication and interpersonal skills, fluent in English and Spanish.
                        </p>
                        <p className='section-4-text' data-aos="fade-left">
                            His experience encompasses many industries including Consumer Electronics and Mobile Technology Manufacturing, Distribution Networks, Electronic Fund Transfers and Finance Solutions Excellent time management and project coordination skills able to properly delegate tasks and manage a work force. A positive business-oriented aptitude, highly skill in to determine challenges and implement solutions, resulting in positive sales growth.
                        </p>
                        <p className='section-4-text' data-aos="fade-left">
                            He has a proven track record throughout employment history, where for more than 30 years he has been COO & CEO in various ventures, domestically and internationally, resulting in a unique wide experience in Latin American and Asia markets.  

                        </p>
                    </div>
                </div>
      
                <div className='section-4-right'>
                    <div className='section-4-image-container' data-aos="slide-left"> 
                   
                    </div>
                    <h2 className='section-4-name' data-aos="fade-down">Tomas Gonzales Anleo</h2>
                    <p className='section-4-position' data-aos="fade-down">Founder & CEO</p>
                </div>
            </div>
            <div className='quote-separator'>
                <p className='quote-separator-text'>
                    Guide for his vision of the future contains a Latin America that has achieved its massive economic potential and become a globally recognized stage for trading.
                </p>
            </div>
            <div className='section-5' id='partners'>
                <div className='section-5-section-limiter'>
                    <div className= 'section-5-header-containers'>
                        <h1 className='section-5-header'>The Partner</h1>
                        <h2 className='section-5-subheader'>Halter Financial group</h2>
                    </div>
                    <div className= 'section-5-info-container'>
                        <div className='section-5-info-left'>
                            <div className='info-wrapper'>
                                <img src={partnerIcon} alt="" className='section-5-icon'/>
                                <p className='section-5-info-text' data-aos="fade">
                                    ACG and HFG have collaborated for the purpose of bringing companies to the U.S. public capital markets.
                                    
                                    Our efforts have resulted in a bespoke strategy for achieving the objectives of raising capital at a valuation that is consistent with the Company’s expectations and trading on a Major Exchange. Together, ACG and HFG shall leverage their expertise to guide your Company through the going public process and also position it for success once the listing is achieved.
                                </p>
                                <p className='section-5-info-text'>
                                    As a result of ACG & Halter Financial Group’s business strategy, the project had added a group of companies associated, which combined with our expertise and efforts, resolve every challenge that our mission required.
                                </p>
                            </div>
                        </div>
                        <div className='section-5-info-right'>
                            <div className="info-wrapper">
                                <img src={hfgIcon} alt="" className='section-5-icon' data-aos="slide-up" />
                                <p className='section-5-info-big-text bold'>
                                    <a href="https://www.halter.com/">
                                        Halter Financial Group
                                    </a>
                                </p>
                                <p className='section-5-info-text'  data-aos="fade">
                                    Founded in 1995, with offices in Dallas, Miami, and Shanghai, HFG is an integrated financial services company focusing on sourcing capital for and direct listing its clients on a Major Exchange through its trademarked Alternative Public Offering (“APO”) model. HFG offers its clients public company, investment banking, and regulatory compliance expertise. HFG has completed hundreds of APO transactions, with clients having secured in excess of $750million in initial round financings.
                                </p>
                                <p className='section-5-link'>
                                    <a href="https://www.halter.com/">
                                        https://www.halter.com/
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section-6'>
                <p className='section-6-text'>
                    If you want to know more about it.
                </p>
                <a href="#Group" className='section-6-link'>
                        The Group
                </a>
            </div>
            <div className='section-7' id='team'>
                <div className='section-limiter'>
                    <div className='section-7-header-container'>
                        <h1 className='section-7-header' data-aos="fade-right">The Team</h1>
                    </div>
                    <p className='section-7-info' data-aos="fade" data-aos-delay="300">
                        ACG has assembled an expert team, led by specialized companies and individuals, that together combine to provide unique and tailored solutions to clients as demonstrated by its overall success rate.
                    </p>
                    <p className='section-7-info' data-aos="fade" data-aos-delay="400">
                        This team of professionals and other “process experts” are dedicated to the success of your company’s “Going Public” initiative. Legal, accounting, investment banking, transfer agent, and investor relations are the key facets of the going public process. Our collective relationships with nationally and internationally recognized firms allow the Company to move forward with confidence.

                    </p>
                </div>
                <div className = 'section-7-photo-row-section'>
                    <div className = 'inner-photo-section'>
                        <h2 className = 'inner-photo-section-header'>Our Executives</h2>
                        <div className='photo-row'>
                            {
                                team.map((element)=>(<SingleExecutive  
                                                        key={element.id}
                                                        onClick = {this.changeTeam.bind(this)}
                                                        selected = {this.state.selectedTeam}
                                                        {...element}
                                                        {...element}
                                                    />))
                            }
                        </div>
                        <div className='advisors-right'>
                            <div className='advisors-right-inner'>
                                <p className='advisors-right-inner-text'>{currentTeam.description}</p>
                                <p className='advisors-right-inner-text'>{currentTeam.extraDescription}</p>
                                <p className='advisors-right-inner-text'>{currentTeam.additionalDescription}</p>
                            </div>
                        </div>
                        {
                            false && 
                            team.map((element) => 
                                <SingleExecutive
                                    key = {element.id} 
                                    {...element}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
            <div className="section-8-no-photo">
                <h1 className="section-8-header" data-aos="slide-left">Board of Advisors</h1>
                <div className="advisor-row">
                    <div className='advisors-left'>
                        {
                            advisors.map((element) => 
                                <AdvisorMiniNoPhoto
                                    key = {element.id}
                                    onClick = {this.changeAdvisor.bind(this)}
                                    selected = {this.state.selectedAdvisor}
                                    {...element}
                                />
                            )
                        }
                    </div>
                    <div className='advisors-right'>
                        <div className='advisors-right-inner'>
                            <p className='advisors-right-inner-text'>{currentAdvisor.description}</p>
                            <p className='advisors-right-inner-text'>{currentAdvisor.extraDescription}</p>
                        </div>
                    </div>
                    {
                        false && 
                        advisors.map((element) => 
                            <SingleAdivsorNoPhoto
                                key = {element.id} 
                                {...element}
                            />
                        )
                    }
                </div>
            </div>
            <div className='grey-separator'></div>
            <div className='section-9' id='Group'>
                <div className='section-9-header-container'>
                    <h1 className='section-9-header' data-aos="fade-down">The Group</h1>
                    <hr className='separator'/>
                    <p className='section-9-text'>In a combined effort, ACG and HFG has assembled an expert team, led by specialized companies and individuals, that together combine to provide unique and tailored solutions to clients as demonstrated by its overall success rate.</p>
                </div>
                <div className='section-9-logo-container'>
                    <div className='section-9-logo-container-single' 
                        data-aos="fade-down"
                        onClick={ (event) => {
                            this.popOverOpen(event,0)
                        }}
                    > 
                        <img src={GRLogo} alt="" className='section-9-logo'/>
                    </div>
                    <div className='section-9-logo-container-single' 
                        data-aos="fade-down"
                        onClick={ (event) => {
                            this.popOverOpen(event,1)
                        }}
                    > 
                        <img src={RoadLogo} alt="" className='section-9-logo'/>
                    </div>
                    <div className='section-9-logo-container-single' 
                        data-aos="fade-down"
                        onClick={ (event) => {
                            this.popOverOpen(event,2)
                        }}
                    > 
                        <img src={NuriLogo} alt="" className='section-9-logo'/>
                    </div>
                    <div className='section-9-logo-container-single' 
                        data-aos="fade-down"
                        onClick={ (event) => {
                            this.popOverOpen(event,3)
                        }}
                    > 
                        <img src={DiestroLogo} alt="" className='section-9-logo'/>
                    </div>
                </div>
            </div>
            <div className='grey-separator'></div>
            <Popover
     
                open={!!groupPopOverOpen}
                anchorEl={groupPopOverOpen}
                onClose={this.popOverState}
                anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
                }}
                className = 'group-pop'
            >
                <div className='group-pop-inner'>
                    <span>
                        {this.state.group[this.state.selectedGroup].text}
                    </span>
                    <a target='_blank' rel="noopener noreferrer" href={this.state.group[this.state.selectedGroup].link} className='pop-link'>More</a>
                </div>
            </Popover>
            </>
        )
    }
}