import React, { Component } from 'react'
import './services.css'
import './contactus.css'
import AncorpLogoIcon from './../../resources/images/logo-icon.png'
import LocationIconB from './../../resources/icons/locationblack.png'
import Iframe from 'react-iframe'
const axios = require('axios');

export default class ContactUs extends Component {
    constructor(props){
        super(props)
        this.state = {
            mapSelected: 0,
            contact_name: '',
            email: '',
            msg: ''
        };
    }

    ErrorMSG = React.createRef();
    ContactName = React.createRef();
    ContactEmail = React.createRef();
    ContactMSG = React.createRef();

    changeName = event => {
        this.setState({ contact_name: event.target.value })
        if(this.state.contact_name.length <= 4)
        {
            this.errorMsgControl('error', 'name');
        }
        else{
            this.errorMsgControl('hidden', 'name');
        }
    }

    changeEmail = event => {
        this.setState({ email: event.target.value })
        if(!this.validateEmail(this.state.email))
        {
            this.errorMsgControl('error', 'email');
        }
        else{
            this.errorMsgControl('hidden', 'email');
        }
    }

    validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    changeMSG = event => {
        this.setState({ msg: event.target.value })
        if(this.state.msg.length <= 10)
        {
            this.errorMsgControl('error', 'msg');
        }
        else{
            this.errorMsgControl('hidden', 'msg');
        }
    }

    errorMsgControl = (typeMsg, source) =>{
        if(source === 'name'){
            this.ErrorMSG.current.innerHTML = "Name is too short.";
        }
        else if(source === 'email'){
            this.ErrorMSG.current.innerHTML = "Not valid email.";
        }
        else if(source === 'msg'){
            this.ErrorMSG.current.innerHTML = "Message is too short.";
        }
        else {
            this.ErrorMSG.current.innerHTML = "Message was sended. We contact you asap.";
        }
        if(typeMsg === 'error')
        {
            this.ErrorMSG.current.classList.remove('msg-success')
            this.ErrorMSG.current.classList.remove('msg-hidden')
            this.ErrorMSG.current.classList.add('msg-showed')
        }
        else if(typeMsg === 'sucess')
        {
            this.ErrorMSG.current.classList.remove('msg-showed')
            this.ErrorMSG.current.classList.remove('msg-hidden')
            this.ErrorMSG.current.classList.add('msg-success')
        }
        else
        {
            this.ErrorMSG.current.classList.remove('msg-success')
            this.ErrorMSG.current.classList.remove('msg-showed')
            this.ErrorMSG.current.classList.add('msg-hidden')
        }
    }
    
    submitContactUsMSG() {
        if(this.state.contact_name.length <= 4)
        {
            this.errorMsgControl('error', 'name');
        }
        else if(!this.validateEmail(this.state.email))
        {
            this.errorMsgControl('error', 'email');
        }
        else if(this.state.msg.length <= 10)
        {
            this.errorMsgControl('error', 'msg');
        }
        else
        {
            const self = this;
            return axios.post('/MailSender/index.php', {
                contact_name: this.state.contact_name,
                email: this.state.email,
                msg: this.state.msg
            })
            .then(function (response) {
                console.log(response);
                self.errorMsgControl('sucess', 'sucess');
                self.ContactName.current.value = "";
                self.ContactEmail.current.value = "";
                self.ContactMSG.current.value = "";
            })
            .catch(function (error) {
                console.log(error);
                self.errorMsgControl('sucess', 'sucess');
                self.ContactName.current.value = "";
                self.ContactEmail.current.value = "";
                self.ContactMSG.current.value = "";
            });
        }
    }

    changeMap = (mapSelected) => {
        this.setState({mapSelected})
    }

    mapStatus = (index) => {
        if(index === this.state.mapSelected)
            return 'selected';

        return '';
    }

    render() {
        const {mapSelected} = this.state;
        const {mapStatus} = this;
        return (
            <>
                <div className='section-contac-us-1' id='ContactUs'>
                    <h3 className="section-contac-us-header">Contact us</h3>
                </div>
                <div className='section-contac-us-2' id='Maps'>
                    <div className='section-contac-us-2-left'>
                        {
                            mapSelected === 0 &&
                            <Iframe 
                                className="contac-us-map"
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD7j1YplHQRvHODBHoINpdn8Rcs6bNoBug&q=804%20South%20Douglas%20Road%20365.%20Coral%20Glabes,%20Miami,%20Fl,%20USA.%2033134&zoom=18" 
                                allowfullscreen>
                            </Iframe >
                        }
                        {
                            mapSelected === 1 &&
                            <Iframe 
                                className="contac-us-map"
                                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD7j1YplHQRvHODBHoINpdn8Rcs6bNoBug&q=Cayena+2566,+Providencia+3a.+Secc,+44630+Guadalajara,+Jal.&zoom=18" 
                                allowfullscreen>
                            </Iframe >
                        }
                    </div>
                    <div className='section-contac-us-2-right'>
                        <div className={`section-contac-us-2-text-container ${mapStatus(0)}`} onClick={(_) => {this.changeMap(0)}}>
                            <img src={LocationIconB} alt="" className='contac-us-location-icon'/>
                            <div>
                                <h4 className='section-contac-us-2-header'>Miami, USA</h4>
                                <p  className='section-contac-us-2-text'>
                                    804 South Douglas Rd 365.
                                    <br/>
                                    Coral Glabes, Fl. 33134. USA
                                </p>
                            </div>
                        </div>
                        <div className={`section-contac-us-2-text-container  ${mapStatus(1)}`} onClick={(_) => {this.changeMap(1)}}>
                            <img src={LocationIconB} alt="" className='contac-us-location-icon'/>
                            <div>
                                <h4 className='section-contac-us-2-header'>Guadalajara, México</h4>
                                <p className='section-contac-us-2-text'>
                                    Cayena 2566, 
                                    <br/>
                                    Providencia 3a Secc, Jal. 44630, México
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='info-section contact-us-get-in-touch-container' id='getintouch'>
                    <div className="contact-us-get-in-touch under-separator">
                        <h3 className="get-in-touch-header">Get in touch</h3>
                        <div className="touch-form">
                            <div className="touch-form-left">
                                <input type="text" placeholder="Name" className='input-custom' name="contact_name" ref={this.ContactName} onChange={this.changeName}/>
                                <input type="text" placeholder="Email" className='input-custom' name="email" ref={this.ContactEmail} onChange={this.changeEmail}/>
                            </div>
                            <div className="touch-form-right">
                                <textarea rows="3" cols="50"  placeholder="Message" className="input-custom-area" name="msg" ref={this.ContactMSG} onChange={this.changeMSG}/>
                            </div>
                        </div>
                        <p className='form-error msg-hidden' ref={this.ErrorMSG}>Valid</p>
                        <button className="submit-button btn btn-white btn-animated" onClick={(_) => {this.submitContactUsMSG()}}>
                            Submit
                        </button>
                    </div>
                    <div className='footer-list-section'> 
                        <ul>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Registration</p>
                            </li>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Investor</p>
                            </li>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Careers</p>
                            </li>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Privacy</p>
                            </li>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Disclaimer</p>
                            </li>
                        </ul> 
                    </div>
                </div>
                <div className='footer contact-us-footer'>
                    <div className='footer-info'>
                        <img src={AncorpLogoIcon} alt="" className='footer-logo'/>
                    </div>
                    <div className='footer-copy'>
                        <div className='footer-final-link'>
                            Copyright © Todos los derechos reservados.
                        </div>
                        <div className='footer-final-link'>
                            Aviso de privasidad 
                        </div>
                    </div>
                </div>
            </>
        )
    }
}