import SubmenuIcon from './../../../resources/icons/submenu.png'
import AncorpLogo from './../../../resources/images/logo.png'
import React, { useState  } from 'react'
import './navbar.css'
import {Link} from "react-router-dom";


export default function Navbar() {
    
    // Declare a new state variable, which we'll call "count"  
    const [menuState, setMenuState] = useState(false);

    const onMenuClick = (params) => {
        setMenuState(!menuState)
    }
    

    return (
        <>
            <div id='navbar' className='navbar btn-animated'>
                <a href='/'>
                    <img src={AncorpLogo} alt="" className='logo-header'/>
                </a>
                <div className='menu-section'>
                    <ul>
                        <li>
                            <a href="/#carousel" className='from-middle'>    
                                Company
                                <img src={SubmenuIcon} alt="" className='submenu-icon' />
                            </a>
                            <ul>
                                <li><a href="/#about">About Us</a></li>
                                <li><a href="/#ourroots">Our Roots</a></li>
                                <li><a href="/#leadership">Leadership</a></li>
                                <li><a href="/#partners">Value partners</a></li>
                                <li><a href="/#team">The team</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/#Group" className='from-middle'>
                                The Group
                            </a>
                        </li>
                        <li>
                            <a href="/#services" className='from-middle'>
                                Our Services
                                <img src={SubmenuIcon} alt="" className='submenu-icon' />
                            </a>
                            <ul>
                                <li><a href="/#financialsolutions">Financial Solutions</a></li>
                                <li><a href="/#goingpublic">Going Public</a></li>
                                <li><a href="/#benefitschallenge">Benefits &amp; Challenges</a></li>
                                <li><a href="/#publicmarket">Public Market</a></li>
                                <li><a href="/#apomodel">The APO Model</a></li>
                                <li><a href="/#mergeracquisitions">Merge &amp; Acquisitions</a></li>
                                <li><a href="/#investorrelationservices">Investor Relations Services</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="/contactus" className='from-middle'>
                                Contact Us
                                <img src={SubmenuIcon} alt="" className='submenu-icon' />
                            </a>
                            <ul>
                                <li><a href="#getintouch">Get in touch</a></li>
                            </ul>
                        </li>
                    </ul>
                    
                    <Link to='/portal' className="btn btn-white btn-animated">
                        Client Portal
                    </Link>
                    
                </div>
            </div>
            <div className="topnav">
                <a href="/#home" className="active">Ancorp</a>
                <div id="myLinks" className={menuState?'showLinks menu-section':''}  >
                    <a href="/#carousel" className = 'navbar-link' onClick = {onMenuClick}>Companys</a>
                    <ul onClick = {onMenuClick}>
                        <li>
                            <a href="/#about">About Us</a>
                        </li>
                        <li>
                            <a href="/#ourroots">Our Roots</a>
                        </li>
                        <li>
                            <a href="/#leadership">Leadership</a>
                        </li>
                        <li>
                            <a href="/#partners">Value partners</a>
                        </li>
                        <li>
                            <a href="/#team">The team</a>
                        </li>
                    </ul>
                    <a href="/#Group" onClick = {onMenuClick} className = 'navbar-link'>The Group</a>
                    <ul>
                    </ul>
                    <a href="/#services" onClick = {onMenuClick} className = 'navbar-link'>Our Services</a>
                    <ul onClick = {onMenuClick}>
                        <li><a href="/#financialsolutions">Financial Solutions</a></li>
                        <li><a href="/#goingpublic">Going Public</a></li>
                        <li><a href="/#benefitschallenge">Benefits &amp; Challenges</a></li>
                        <li><a href="/#publicmarket">Public Market</a></li>
                        <li><a href="/#apomodel">The APO Model</a></li>
                        <li><a href="/#mergeracquisitions">Merge &amp; Acquisitions</a></li>
                        <li><a href="/#investorrelationservices">Investor Relations Services</a></li>
                    </ul>
                    <a href="/contactus" onClick = {onMenuClick} className = 'navbar-link'>Contact Us</a>
                    <ul onClick = {onMenuClick}>
                        <li>
                            <a href="#getintouch">Get in touch</a>
                        </li>
                    </ul>
                </div>
                <div onClick = {onMenuClick}>
                    <a href='javascript:void(0);' className = "icon">
                        <i className="fa fa-bars"></i>
                    </a>
                </div>
            </div>
        </>
    )
}
