import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/app';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
if(window.AOS !== undefined)
{
    window.AOS.init();
}

var prevScrollpos = window.pageYOffset;
window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
 
  if (window.screen.width>480 && prevScrollpos > currentScrollPos) {
    document.getElementById("navbar").classList.add("withBackground");
  } else {
    document.getElementById("navbar").classList.remove("withBackground");
  }
  prevScrollpos = currentScrollPos;
} 