import React, {Fragment, useState, useEffect, Component, useCallback  } from 'react'
import './services.css'
import AncorpLogoIcon from './../../resources/images/logo-icon.png'
import LocationIcon from './../../resources/icons/location.png'
import FinancialIcon from './../../resources/icons/financial.png'
import AdquisitionIcon from './../../resources/icons/acquisitions.png'
import InvestorIcon from './../../resources/icons/investor.png'
import GPublicIcon from './../../resources/icons/going-public.png'
import FactoringIcon from './../../resources/icons/factoring.png'
import ABLIcon from './../../resources/icons/ABL.png'
import BenefitIcon from './../../resources/icons/benefitsicon.png'
import ChallengeIcon from './../../resources/icons/challengeicon.png'
import SuplierIcon from './../../resources/icons/suplier.png'
import PurchaseIcon from './../../resources/icons/purchase.png'

import Separator from './../../resources/images/separator.png'
import RArrow from './../../resources/images/right-arrow.png'
import LArrow from './../../resources/images/left-arrow.png'

import CubeA from './../../resources/images/cube.png'
import CubeB from './../../resources/images/cubeB.png'
import CubeC from './../../resources/images/cubeC.png'

import RArrowAlt from './../../resources/images/arrowRalternative.png'
import LArrowAlt from './../../resources/images/arrowLalternative.png'

const axios = require('axios');

export default function Services() {
    const [publicSelect, setPublicSelect] = useState(0);
    const whyPublicSelector = (id) => {
        setPublicSelect(id);
    }

    const [publicMarketSelect, setPublicMarketSelect] = useState(0);
    const publicMarketSelector = (id) => {
        setPublicMarketSelect(id);
    }
    
    const [cubeDirectionState, setcubeDirectionState] = useState(0);
    const [cubeState, setcubeState] = useState(0);
    const arrowClick = (direction) => {
        let change = 0
        if(direction === 'right'){
            change = 1 
        }else{
            change = -1 
        }
        setcubeDirectionState(change);
        setcubeState(cubeState+change);
    }

    // Variables que apuntan a lugares en el DOM
    const benefitsRow = React.createRef();

    const rigthArrowRef = React.createRef();
    const leftArrowRef = React.createRef();

    const CubeRef = React.createRef();
    const CubeBRef = React.createRef();
    const CubeCRef = React.createRef();

    const InfoRef = React.createRef();
    const InfoBRef = React.createRef();
    const InfoCRef = React.createRef();

    const ApoModelItem0 = React.createRef();
    const ApoModelRightArrow = React.createRef();
    const ApoModelItem1 = React.createRef();
    const ApoModelLeftArrow = React.createRef();
    const [apoModelSelect, setApoModelSelect] = useState(0);
    const apoModelSelector = (id) => {
        setApoModelSelect(id);
    }

    const MergerAcqItem0 = React.createRef();
    const MergerAcqItem1 = React.createRef();
    const MergerAcqItem2 = React.createRef();
    const MergerAcqItem3 = React.createRef();
    const MergerAcqItemPanel = React.createRef();
    const MergerAcqRightArrow = React.createRef();
    const MergerAcqLeftArrow = React.createRef();
    const [mergeAcqDirectionState, setmergeAcqDirectionState] = useState(0);
    const [mergeAcqSelect, setmergeAcqSelect] = useState(0);
    const mergeAcqSelector = (id, direction) => {
        let change = 0
        if(direction === 'right'){
            change = 1 
        }else{
            change = -1 
        }
        setmergeAcqDirectionState(change);
        setmergeAcqSelect(id);
    }
    
    useEffect(() => {
        switch(cubeState)
        {
            case 0:
                console.log('Posicion inicial');
                benefitsRow.current.classList.remove('benefits-row-right');
                benefitsRow.current.classList.remove('benefits-row-left');

                CubeBRef.current.classList.add('hide-cube')
                CubeCRef.current.classList.add('hide-cube')

                rigthArrowRef.current.classList.remove('hidde-arrow')
                leftArrowRef.current.classList.remove('hidde-arrow')

                rigthArrowRef.current.classList.add('non-events')
                leftArrowRef.current.classList.add('non-events')

                CubeRef.current.classList.remove('hide-cube')
                
                InfoBRef.current.classList.remove('animation-left-in')
                InfoCRef.current.classList.remove('animation-right-in')

                switch(cubeDirectionState)
                {
                    case 1:
                        InfoBRef.current.classList.add('animation-left-out')
                        break;
                    case -1:
                        InfoCRef.current.classList.add('animation-right-out')
                        break;
                    default:
                        break;
                }

                setTimeout(function(){
                    InfoRef.current.classList.remove('benefits-info-hidden')
                    switch(cubeDirectionState)
                    {
                        case 1:
                            InfoBRef.current.classList.remove('animation-left-out')

                            InfoRef.current.classList.add('animation-right-in')
                            break;
                        case -1:
                            InfoCRef.current.classList.remove('animation-right-out')

                            InfoRef.current.classList.add('animation-left-in')
                            break;
                        default:
                            break;
                    }

                    InfoCRef.current.classList.add('benefits-info-hidden')
                    InfoBRef.current.classList.add('benefits-info-hidden')

                    rigthArrowRef.current.classList.remove('non-events')
                    leftArrowRef.current.classList.remove('non-events')
               
                }, 500);
            break;
            case 1:
                console.log('Solo Challenges');
                benefitsRow.current.classList.add('benefits-row-right');

                CubeRef.current.classList.add('hide-cube')
                CubeBRef.current.classList.add('hide-cube')

                CubeBRef.current.classList.remove('hide-cube')

                rigthArrowRef.current.classList.add('hidde-arrow')
                
                leftArrowRef.current.classList.add('non-events')

                InfoBRef.current.classList.add('benefits-info-hidden')

                InfoRef.current.classList.remove('animation-left-in')
                InfoRef.current.classList.remove('animation-right-in')

                InfoRef.current.classList.add('animation-left-out')
                setTimeout(function(){
                    InfoCRef.current.classList.remove('benefits-info-hidden')
                    InfoCRef.current.classList.add('animation-right-in')
                    
                    InfoRef.current.classList.remove('animation-left-out')
                    InfoRef.current.classList.add('benefits-info-hidden')
                    
                    leftArrowRef.current.classList.remove('non-events')
               
                }, 500);
            break;
            case -1:
                console.log('Solo Benefits');
                benefitsRow.current.classList.add('benefits-row-left');

                CubeRef.current.classList.add('hide-cube')
                CubeCRef.current.classList.add('hide-cube')

                CubeCRef.current.classList.remove('hide-cube')

                leftArrowRef.current.classList.add('hidde-arrow')

                rigthArrowRef.current.classList.add('non-events')

                InfoCRef.current.classList.add('benefits-info-hidden')

                InfoRef.current.classList.remove('animation-left-in')
                InfoRef.current.classList.remove('animation-right-in')

                InfoRef.current.classList.add('animation-right-out')
                setTimeout(function(){
                    InfoBRef.current.classList.remove('benefits-info-hidden')
                    InfoBRef.current.classList.add('animation-left-in')
                    
                    InfoRef.current.classList.remove('animation-right-out')
                    InfoRef.current.classList.add('benefits-info-hidden')

                    rigthArrowRef.current.classList.remove('non-events')
                }, 500);
            break;
            default: 
                console.log('Default'); 
            break;
        }
    },[cubeState, cubeDirectionState]
    );

    useEffect(() => {
        switch(apoModelSelect)
        {
            case 0:
                ApoModelItem1.current.classList.remove('animation-right-in')
                ApoModelLeftArrow.current.classList.remove('animation-right-in')
                ApoModelItem1.current.classList.add('animation-right-out')
                ApoModelLeftArrow.current.classList.add('animation-right-out')
                ApoModelLeftArrow.current.classList.add('non-events')

                setTimeout(function(){
                    ApoModelItem0.current.classList.remove('non-visible')
                    ApoModelRightArrow.current.classList.remove('non-visible')

                    ApoModelItem0.current.classList.add('animation-left-in')
                    ApoModelRightArrow.current.classList.add('animation-left-in')

                    ApoModelItem1.current.classList.remove('animation-right-out')
                    ApoModelLeftArrow.current.classList.remove('animation-right-out')
                    ApoModelLeftArrow.current.classList.remove('non-events')

                    ApoModelItem1.current.classList.add('non-visible')
                    ApoModelLeftArrow.current.classList.add('non-visible')
                }, 500);
                break;
            case 1:
                ApoModelItem0.current.classList.remove('animation-left-in')
                ApoModelRightArrow.current.classList.remove('animation-left-in')
                ApoModelItem0.current.classList.add('animation-left-out')
                ApoModelRightArrow.current.classList.add('animation-left-out')
                ApoModelRightArrow.current.classList.add('non-events')

                setTimeout(function(){
                    ApoModelItem1.current.classList.remove('non-visible')
                    ApoModelLeftArrow.current.classList.remove('non-visible')

                    ApoModelItem1.current.classList.add('animation-right-in')
                    ApoModelLeftArrow.current.classList.add('animation-right-in')

                    ApoModelItem0.current.classList.remove('animation-left-out')
                    ApoModelRightArrow.current.classList.remove('animation-left-out')
                    ApoModelRightArrow.current.classList.remove('non-events')

                    ApoModelItem0.current.classList.add('non-visible')
                    ApoModelRightArrow.current.classList.add('non-visible')
                }, 500);
                break;
            default:
        }
    },[apoModelSelect]
    );

    useEffect(() => {
        switch(mergeAcqSelect)
        {
            case 0:
                MergerAcqItemPanel.current.classList.add('non-events')
                MergerAcqRightArrow.current.classList.add('non-events')
                MergerAcqLeftArrow.current.classList.add('non-events')

                MergerAcqItem2.current.classList.remove('animation-left-in')
                MergerAcqItem3.current.classList.remove('animation-left-in')
                MergerAcqItem2.current.classList.remove('animation-right-in')
                MergerAcqItem3.current.classList.remove('animation-right-in')

                let outAnim, inAnim;
                switch(mergeAcqDirectionState){
                    case -1:
                        outAnim = 'animation-right-out'
                        inAnim = 'animation-left-in'
                        break;
                    case 1:
                        outAnim = 'animation-left-out'
                        inAnim = 'animation-right-in'
                        break;
                    default:
                        break;
                }
                
                MergerAcqItem2.current.classList.add(outAnim)
                MergerAcqItem3.current.classList.add(outAnim)

                setTimeout(function(){
                    MergerAcqItem0.current.classList.remove('non-visible')
                    MergerAcqItem1.current.classList.remove('non-visible')
                    MergerAcqItem0.current.classList.add(inAnim)
                    MergerAcqItem1.current.classList.add(inAnim)

                    MergerAcqItem2.current.classList.remove(outAnim)
                    MergerAcqItem3.current.classList.remove(outAnim)
                    MergerAcqItem2.current.classList.add('non-visible')
                    MergerAcqItem3.current.classList.add('non-visible')

                    MergerAcqItemPanel.current.classList.remove('non-events')
                    MergerAcqRightArrow.current.classList.remove('non-events')
                    MergerAcqLeftArrow.current.classList.remove('non-events')
                }, 500);
                break;
            case 1:
                MergerAcqItemPanel.current.classList.add('non-events')
                MergerAcqRightArrow.current.classList.add('non-events')
                MergerAcqLeftArrow.current.classList.add('non-events')

                MergerAcqItem0.current.classList.remove('animation-left-in')
                MergerAcqItem1.current.classList.remove('animation-left-in')
                MergerAcqItem0.current.classList.remove('animation-right-in')
                MergerAcqItem1.current.classList.remove('animation-right-in')

                let outAnim1, inAnim1;
                switch(mergeAcqDirectionState){
                    case -1:
                        outAnim1 = 'animation-right-out'
                        inAnim1 = 'animation-left-in'
                        break;
                    case 1:
                        outAnim1 = 'animation-left-out'
                        inAnim1 = 'animation-right-in'
                        break;
                    default:
                        break;
                }

                MergerAcqItem0.current.classList.add(outAnim1)
                MergerAcqItem1.current.classList.add(outAnim1)
                setTimeout(function(){
                    MergerAcqItem2.current.classList.remove('non-visible')
                    MergerAcqItem3.current.classList.remove('non-visible')
                    MergerAcqItem2.current.classList.add(inAnim1)
                    MergerAcqItem3.current.classList.add(inAnim1)

                    MergerAcqItem0.current.classList.remove(outAnim1)
                    MergerAcqItem1.current.classList.remove(outAnim1)
                    MergerAcqItem0.current.classList.add('non-visible')
                    MergerAcqItem1.current.classList.add('non-visible')

                    MergerAcqItemPanel.current.classList.remove('non-events')
                    MergerAcqRightArrow.current.classList.remove('non-events')
                    MergerAcqLeftArrow.current.classList.remove('non-events')
                }, 500);
                break;
            default:
                break;
        }
    },[mergeAcqSelect, mergeAcqDirectionState]
    );

    const ErrorMSG = React.createRef();
    const ContactName = React.createRef();
    const ContactEmail = React.createRef();
    const ContactMSG = React.createRef();

    const [contact_name, setContactName] = useState('');
    const changeName = event => {
        setContactName(event.target.value)
        if(contact_name.length <= 4)
        {
            errorMsgControl('error', 'name');
        }
        else{
            errorMsgControl('hidden', 'name');
        }
    }

    const [email, setEmail] = useState('');
    const changeEmail = event => {
        setEmail(event.target.value)
        if(!validateEmail(email))
        {
            errorMsgControl('error', 'email');
        }
        else{
            errorMsgControl('hidden', 'email');
        }
    }

    const validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const [msg, setMSG] = useState('');
    const changeMSG = event => {
        setMSG(event.target.value)
        if(msg.length <= 10)
        {
            errorMsgControl('error', 'msg');
        }
        else{
            errorMsgControl('hidden', 'msg');
        }
    }

    const errorMsgControl = (typeMsg, source) =>{
        if(source === 'name'){
            ErrorMSG.current.innerHTML = "Name is too short.";
        }
        else if(source === 'email'){
            ErrorMSG.current.innerHTML = "Not valid email.";
        }
        else if(source === 'msg'){
            ErrorMSG.current.innerHTML = "Message is too short.";
        }
        else {
            ErrorMSG.current.innerHTML = "Message was sended. We contact you asap.";
        }
        if(typeMsg === 'error')
        {
            ErrorMSG.current.classList.remove('msg-success')
            ErrorMSG.current.classList.remove('msg-hidden')
            ErrorMSG.current.classList.add('msg-showed')
        }
        else if(typeMsg === 'sucess')
        {
            ErrorMSG.current.classList.remove('msg-showed')
            ErrorMSG.current.classList.remove('msg-hidden')
            ErrorMSG.current.classList.add('msg-success')
        }
        else
        {
            ErrorMSG.current.classList.remove('msg-success')
            ErrorMSG.current.classList.remove('msg-showed')
            ErrorMSG.current.classList.add('msg-hidden')
        }
    }
    
    const submitContactUsMSG = () => {
        if(contact_name.length <= 4)
        {
            errorMsgControl('error', 'name');
        }
        else if(!validateEmail(email))
        {
            errorMsgControl('error', 'email');
        }
        else if(msg.length <= 10)
        {
            errorMsgControl('error', 'msg');
        }
        else
        {
            return axios.post('/MailSender/index.php', {
                contact_name: contact_name,
                email: email,
                msg: msg
            })
            .then(function (response) {
                console.log(response);
                errorMsgControl('sucess', 'sucess');
                ContactName.current.value = "";
                ContactEmail.current.value = "";
                ContactMSG.current.value = "";
            })
            .catch(function (error) {
                console.log(error);
                errorMsgControl('sucess', 'sucess');
                ContactName.current.value = "";
                ContactEmail.current.value = "";
                ContactMSG.current.value = "";
            });
        }
    }

    return (
        <Fragment>
            <div className='services-section' id='services'>
                <h1 className='services-header'>Our Services</h1>
                <p className='services-info'>
                    Our strategic advisory cover a full range of services such as Financial Solutions, Business Analysis, Strategic Business Planning, Management, Sales Agency, Marketing, Brand Development, Advertisement and specialized Legal Processes among others. Our services are organized in 4 divisions:
                </p>
                <div className='service-icon-container'>
                    <div className='service-item'>
                        <a href="#financialsolutions">
                            <img src={FinancialIcon} alt="" data-aos="zoom-in-down" className='service-item-icon'/>
                        </a>
                        <p className='service-item-title'>Financial</p>
                        <p className='service-item-subtitle'>Solutions</p>
                    </div>
                    <div className='service-item'>
                        <a href="#goingpublic">
                            <img src={GPublicIcon} alt=""  data-aos="zoom-in-down" className='service-item-icon'/>
                        </a>
                        <p className='service-item-title'>Going</p>
                        <p className='service-item-subtitle'>Public</p>
                    </div>
                    <div className='service-item'>
                        <a href="#mergeracquisitions">
                            <img src={AdquisitionIcon} alt=""  data-aos="zoom-in-down" className='service-item-icon'/>
                        </a>
                        <p className='service-item-title'>Merge &</p>
                        <p className='service-item-subtitle'>Adquisitions</p>
                    </div>
                    <div className='service-item'>
                        <a href="#investorrelationservices">
                            <img src={InvestorIcon} alt=""  data-aos="zoom-in-down" className='service-item-icon'/>
                        </a>
                        <p className='service-item-title'>Investor</p>
                        <p className='service-item-subtitle'>Relations Services</p>
                    </div>
                </div>  
                <p className='services-info'>
                    ACG develops a personalized solution for each client, takes leadership on the development of the project and represents the client’s interest during the different steps of the process by identifying and securing the right strategies and partners.
                </p>
            </div>
            <div className="header-section relative" id='financialsolutions'>
                <h1 className='header-section-header' data-aos="zoom-in">Financial Solutions</h1>
                <div className = "yellow-indicator">
                    <p className='header-section-info ' >ACG develops a customized solution for each client, assumes the leadership in the development of the project and represents the interests of the clients during the different steps of the process, identifying and ensuring the appropriate strategies and finance partners.</p>
                </div>
            </div>
            <div className='info-section'>
                <div className="half-row">
                    <div className='half-row-section'>
                        <img src={FactoringIcon} alt="" className='half-row-section-img'/>
                        <h2 className='half-row-section-header'>Factoring:</h2>
                        <p className='half-row-section-info'> If you're waiting for your Accounts Receivable to come in but need that money now, our financial team can help you. For a nominal rate we will purchase your Accounts Receivable in order to get you the money you need to keep your business going into the future.</p>
                    </div>
                    <div className='half-row-section factoring-img-section ' data-aos="fade-left">
                        
                    </div>
                </div>
                <div className="half-row reverse">
                    <div className='half-row-section'>
                        <img src={ABLIcon} alt="" className='half-row-section-img'/>
                        <h2 className='half-row-section-header'>Asset-Based Lending:</h2>
                        <p className='half-row-section-info'> Secure financing using the value of your assets. By using the value of your assets such as Accounts Receivable, liquid inventory, or equipment, you can secure a revolving loan that will help you further your goals into the future.</p>
                    </div>
                    <div className='half-row-section abl-img-section' data-aos="fade-right">

                    </div>
                </div>
                <div className="half-row">
                    <div className='half-row-section'>
                        <img src={SuplierIcon} alt="" className='half-row-section-img'/>
                        <h2 className='half-row-section-header'>Supplier Credit:</h2>
                        <p className='half-row-section-info'> Looking to expand your current supply chains? Or maybe you’re looking to cement a supply chain during a time of uncertain funding. No matter your supply credit needs, we will provide you with a tailor-made solution.</p>
                    </div>
                    <div className='half-row-section suplier-img-section' data-aos="fade-left">
                        
                    </div>
                </div>
                <div className="half-row reverse">
                    <div className='half-row-section'>
                        <img src={PurchaseIcon} alt="" className='half-row-section-img'/>
                        <h2 className='half-row-section-header'>Purchase Order Financing:</h2>
                        <p className='half-row-section-info'> Do you have a big purchase coming up? Whether for new facilities or paying for a vital service, we'll be happy to finance your big purchase needs so you can take that big step forward without any worries.</p>
                    </div>
                    <div className='half-row-section purchase-img-section' data-aos="fade-right">

                    </div>
                </div>
            </div>
            <div className="header-section" id='goingpublic'>
                <h1 className='header-section-header-primary' data-aos="flip-left">Public Market Services</h1>
                <h1 className='header-section-second-header' data-aos="flip-left">
                    Going Public
                </h1>
                <div className = "yellow-indicator">
                <p className='header-section-info' data-aos="flip-left">As part of the comprehensive planning, ACG provides its clients an Integral Solution that would not only meet their immediate challenges, but also create a path to their development plan by taking the company to next Level: GOING PUBLIC</p>
                </div>
            </div>
            <div className='why-go-public-section'>
                <div className="why-go-public-left">
                    <div className='why-go-row' data-aos="fade-right">
                        <h2 className='why-go-header-1'>Why go</h2>
                        <h1 className='why-go-header-2'>public?</h1>
                        <p className='why-go-header-info'>The main reason companies decide to get listed, however, is to raise money - a lot of money –but this simplest objective, cannot blind a much bigger accomplishment and group of benefits
                        </p>
                    </div>
                </div>
                <div className="why-go-public-right">
                    <div className="go-public-buttons">
                        <div className="go-public-buttons-inner">
                            <div className="go-public-buttons-row">
                                <div className={`go-public-button ${publicSelect === 0?'active':''}`}onClick={(_) => {whyPublicSelector(0)}}>
                                    <h3 className="row-public-header-1">Access to</h3>
                                    <h3 className="row-public-header-2">Capital</h3>
                                </div>
                                <div className={`go-public-button ${publicSelect === 1?'active':''}`} onClick={(_) => {whyPublicSelector(1)}}>
                                    <h3 className="row-public-header-1">Enhanced</h3>
                                    <h3 className="row-public-header-2">Profile</h3>
                                </div>
                                <div className={`go-public-button ${publicSelect === 2?'active':''}`} onClick={(_) => {whyPublicSelector(2)}}>
                                    <h3 className="row-public-header-1">Increased</h3>
                                    <h3 className="row-public-header-2">Visibility</h3>
                                </div>
                            </div>
                            <div className="go-public-buttons-row" >
                                <div className={`go-public-button ${publicSelect === 3?'active':''}`} onClick={(_) => {whyPublicSelector(3)}}>
                                    <h3 className="row-public-header-1">Ability to Attract</h3>
                                    <h3 className="row-public-header-2">Better Employees</h3>
                                </div>
                                <div className={`go-public-button ${publicSelect === 4?'active':''}`} onClick={(_) => {whyPublicSelector(4)}}>
                                    <h3 className="row-public-header-1">Ability to</h3>
                                    <h3 className="row-public-header-2">Maintain Control</h3>
                                </div>
                                <div className={`go-public-button ${publicSelect === 5?'active':''}`} onClick={(_) => {whyPublicSelector(5)}}>
                                    <h3 className="row-public-header-1">Reduction of the Cost</h3>
                                    <h3 className="row-public-header-2">of Other Capital</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="go-public-info">
                        <div className="go-public-info-inner">
                            {
                                publicSelect === 0 &&
                                <p className="row-public-header-info">Companies listed on a stock exchange can quickly raise affordable capital by issuing more shares for investors to purchase. The capital raised from the issuance of shares can be used to help the company grow and pay for different business costs.</p>
                            }
                            {
                                publicSelect === 1 &&
                                <p className="row-public-header-info">Companies listed on a stock exchange are much more recognizable and visible than their privately held counterparts. The increased visibility that comes with being listed on an exchange can help a company attract new clients.</p>
                            }
                            {
                                publicSelect === 2 &&
                                <p className="row-public-header-info">There may be no better PR move for a company than to go public, as the process generates free publicity and excitement in the marketplace for the company. A successful IPO also results in a flood of cash for a newly public company, and with this additional money, companies can further expand their operations, or allow companies to offer more lucrative share option packages to employees.</p>
                            }
                            {
                                publicSelect === 3 &&
                                <p className="row-public-header-info">High quality employees are attracted to employers that have name recognition and visibility. Stock exchanges can help companies become household names and better attract employers capable of making the company more profitable, and also able to better compensate employees to keep them from moving to competitors.</p>
                            }
                            {
                                publicSelect === 4 &&
                                <p className="row-public-header-info">Companies not listed on stock exchanges typically rely on capital provided by venture capitalists and private investors. In exchange for purchasing shares of a privately held company, investors usually insist on having some degree of control of the company, including having members appointed to the board. Stock exchanges allow companies to maintain more autonomy and control, because people who purchase the shares of a publicly traded company only have the limited rights afforded to all shareholders.</p>
                            }
                            {
                                publicSelect === 5 &&
                                <p className="row-public-header-info">Going public reduces the costs of obtaining capital through bank loans. Banks view publicly traded companies as less of a credit risk than their privately held counterparts, because publicly traded companies have access to other capital and the auditing requirements for public companies make their financial condition more transparent.</p>
                            }
                            {
                                publicSelect === 5 &&
                                <p className="row-public-header-info">Finally, a very important one too, provide shareholder the “X Strategy” to capitalize their life’s achievements without damaging the company’s future.</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='vanish-strip'>
            </div>
            <div className='benefits-row' id='benefitschallenge' ref={benefitsRow}>
                <div className='benefits-car-base'>
                    <div className='benefits-info-1'  ref={InfoRef}>
                        <div className='benefits-header-container'>
                            <div className="benefits-header-wraper">
                                <h1 className='benefits-header-tittle'>Benefits &</h1>
                                <h1 className='benefits-header-tittle'>Challenges</h1>
                            </div>
                        </div>
                        <p className='benefits-row-text'>
                            In electing to follow our proposed strategy, the company will be rewarded with extraordinaries benefits, but also must be aware of the new challenge and responsibilities:
                        </p>
                    </div>
                    <div className='benefits-info-1-alt'  ref={InfoBRef}>
                        <div className='benefits-info-row'>
                            <h1 className='benefits-header'>Benefits</h1>
                            <div className='benefits-inner-row'>
                                <div className='benefits-info-item'>
                                    <img className='benefit-info-icon' src={BenefitIcon} alt=""/>
                                    <p className='benefit-info-text'>Ability to raise capital in a shorter window and at a higher valuation, so as to minimize dilution</p>
                                </div>
                                <div className='benefits-info-item'>
                                    <img className='benefit-info-icon' src={BenefitIcon} alt=""/>
                                    <p className='benefit-info-text'>Access capital to act on exponential growth opportunities</p>
                                </div>
                                <div className='benefits-info-item'>
                                    <img className='benefit-info-icon' src={BenefitIcon} alt=""/>
                                    <p className='benefit-info-text'>Use of stock as a currency to make strategic acquisitions</p>
                                </div>
                                <div className='benefits-info-item'>
                                    <img className='benefit-info-icon' src={BenefitIcon} alt=""/>
                                    <p className='benefit-info-text'>Incentivize current and future executives and employees</p>
                                </div>
                                <div className='benefits-info-item'>
                                    <img className='benefit-info-icon' src={BenefitIcon} alt=""/>
                                    <p className='benefit-info-text'>Obtain cache over competitors as transparency makes third parties more willing to do business with a public company
                                    </p>
                                </div>
                                <div className='benefits-info-item'>
                                    <img className='benefit-info-icon' src={BenefitIcon} alt=""/>
                                    <p className='benefit-info-text'>Liquidity and estate planning opportunities for founders
                                    </p>
                                </div>
                                <div className='benefits-info-item'>
                                    <img className='benefit-info-icon' src={BenefitIcon} alt=""/>
                                    <p className='benefit-info-text'>Easier business transition planning
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='benefits-info-1-alt'  ref={InfoCRef}>
                        <div className='challenges-info-row'>
                            <h1 className='benefits-header'>Challenges</h1>
                            <div className='challenges-inner-row'>
                                <div className='benefits-full-item'>
                                    <img className='benefit-info-icon' src={ChallengeIcon} alt=""/>
                                    <p className='benefits-full-item-description'>The Company will be subject to corporate governance requirements mandated by the Major Exchanges (including requirements such as having the Board of Directors being comprised of a majority of independent members, shareholder approval of certain actions, such as a name change or dividend declaration, and Executive Compensations and Audit Committee oversight over the actions of the entire Board of Directors that relate to executive compensation and the audit of the Company’s financial statements)
                                    </p>
                                </div>
                                <div className='benefits-item'>
                                    <img className='benefit-info-icon' src={ChallengeIcon} alt=""/>
                                    <p className='benefits-full-item-description'>Required transparency, the Company will be obligated to disclose to the public matters such as financial performance, operational strategies, ownership percentages, and executive compensation
                                    </p>
                                </div>
                                <div className='benefits-item'>
                                    <img className='benefit-info-icon' src={ChallengeIcon} alt=""/>
                                    <p className='benefits-full-item-description'>Company will be obligated to file periodic performance reports (quarterly and annually), and as such the performance of the Company will be judged on a quarterly basis
                                    </p>
                                </div>
                                <div className='benefits-item'>
                                    <img className='benefit-info-icon' src={ChallengeIcon} alt=""/>
                                    <p className='benefits-full-item-description'>The Company will incur exchange, legal and accounting costs that are unique to public companies
                                    </p>
                                </div>
                                <div className='benefits-item'>
                                    <img className='benefit-info-icon' src={ChallengeIcon} alt=""/>
                                    <p className='benefits-full-item-description'>Officers, Directors and 10% Shareholders will have to follow certain selling protocols to avoid violating insider-trading
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <img src={CubeA} alt="" className='cube hide-cube' ref={CubeRef}/>
                    <img src={CubeB} alt="" className='cube hide-cube' ref={CubeBRef}/>
                    <img src={CubeC} alt="" className='cube hide-cube' ref={CubeCRef}/>

                    <img src={RArrow} alt="" className='right-arrow' ref={rigthArrowRef} onClick={(_) => arrowClick('right')}/>
                    <img src={LArrow} alt="" className='left-arrow' ref={leftArrowRef} onClick={(_) => arrowClick('left')}/>
                </div>
            </div>
            
            <div className='vanish-strip'>
            </div>

            <div className='public-market-info-row' id='publicmarket'>
                <div className='public-market-part-1'>
                    <div className='public-market-info-left'>
                        <div className='public-market-header-container'>
                            <h1 className='public-market-header-1'>Public</h1>
                            <h1 className='public-market-header-1'>Market</h1>
                            <h1 className='public-market-header-2'>Service</h1>
                            <h1 className='public-market-header-2'>Breakdown</h1>
                        </div>
                    </div>
                    <div className='public-market-info-right'>
                        <p className='public-market-info-text'>
                            By accepting the responsibility, and understanding the challenges of the process, ACG has entered in a partnership agreement and assembled an expert Group of advisors. Our collective relationships with nationally and internationally recognized firms allow the Company to move forward with confidence on the different aspect of the process.
                        </p>
                    </div>
                </div>

                <div className="public-market-container">
                    <div className="public-market-buttons">
                        <div className="public-market-buttons-inner">
                            <div className="public-market-buttons-row">
                                <div className={`public-market-button ${publicMarketSelect === 0?'active':''}`}onClick={(_) => {publicMarketSelector(0)}}>
                                    <h3 className="pmiheader-1">Finance</h3>
                                    <h3 className="pmiheader-2">Solutions</h3>
                                </div>
                                <div className={`public-market-button ${publicMarketSelect === 1?'active':''}`} onClick={(_) => {publicMarketSelector(1)}}>
                                    <h3 className="pmiheader-1">Audit and reporting</h3>
                                    <h3 className="pmiheader-2">Compliances</h3>
                                </div>
                                <div className={`public-market-button ${publicMarketSelect === 2?'active':''}`} onClick={(_) => {publicMarketSelector(2)}}>
                                    <h3 className="pmiheader-1">Corporate</h3>
                                    <h3 className="pmiheader-2">Governance</h3>
                                </div>
                            </div>
                            <div className="public-market-buttons-row" >
                                <div className={`public-market-button ${publicMarketSelect === 3?'active':''}`} onClick={(_) => {publicMarketSelector(3)}}>
                                    <h3 className="pmiheader-1">Market Readiness</h3>
                                    <h3 className="pmiheader-2">Division</h3>
                                </div>
                                <div className={`public-market-button ${publicMarketSelect === 4?'active':''}`} onClick={(_) => {publicMarketSelector(4)}}>
                                    <h3 className="pmiheader-1">Certifications and</h3>
                                    <h3 className="pmiheader-2">Consultation</h3>
                                </div>
                                <div className={`public-market-button ${publicMarketSelect === 5?'active':''}`} onClick={(_) => {publicMarketSelector(5)}}>
                                    <h3 className="pmiheader-1">Market</h3>
                                    <h3 className="pmiheader-2">Introduction Division</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="public-market-info">
                        <div className="public-market-info-inner">
                            {
                                publicMarketSelect === 0 &&
                                <p className='public-market-item-info'>Our work begins with evaluating the position and financial challenges of the company; and from this analysis, the presentation of a proposal for a “Bridge” Financial Solution, which allows it to transit the public process without affecting its cash flow while simultaneously maximizing the growth opportunities.
                                </p>
                            }
                            {
                                publicMarketSelect === 0 &&
                                <p className='public-market-item-info'>As part of the process, Ancorp also makes capital injections available to its clients, which can be treated (within the private company) as future investments, convertible into shares of the future public company. For this, it has a pull of investors, investment banks and specialized personnel that allow tailored solutions.
                                </p>    
                            }
                            {
                                publicMarketSelect === 1 &&
                                <p className='public-market-item-info'>Publicly held companies typically face more audits based on requirements from government regulatory agencies and stock exchanges. These companies require more audits because investment firms and individual investors have a financial stake in the company’s financial returns. Our team, in a combined effort with our preferred providers, will work to  assist and train the client team on the process to get their finance procedures and information in compliance with regulatory and principles defined by the US Security and Exchange commission (SEC).
                                </p>
                            }
                            {
                                publicMarketSelect === 2 &&
                                <p className='public-market-item-info'>Candidates have the ability to show potential investors that they have satisfied all regulatory requirements related to the operation of their business. A company that has been involved in multiple regulatory investigations or subject to numerous lawsuits will incur difficulty in raising capital.
                                </p>
                            }
                            {
                                publicMarketSelect === 2 &&
                                <p className='public-market-item-info'>Good governance is also demonstrated by showing potential investors that a company either currently has appointed or has identified strong candidates to serve on its Board of Directors once the APO is complete. Qualified candidates are those who have industry experience, financial acumen and/or public company experience that gives investors’ confidence that they are providing appropriate oversight.
                                </p>
                            }
                            {
                                publicMarketSelect === 3 &&
                                <p className='public-market-item-info'>Another specialized part of ACG services is dedicated to making sure that client companies are ready to go public, assisting in all due diligences, formularies, forms, approvals, reporting and applications.
                                </p>
                            }
                            {
                                publicMarketSelect === 4 &&
                                <p className='public-market-item-info'>As a part of going public, all companies need to meet certain requirements and regulations. Depending on the country, they will also need licenses and certifications. Our legal team will help you work through this process.
                                </p>
                            }
                            {
                                publicMarketSelect === 5 &&
                                <p className='public-market-item-info'>Once company is ready, ACG and HFG will introduce the client projection and specific to the market, to Investment bank partners, who will provide the client with finance alternative, promotion planning and now, as part of the group develop the further steps for the market launching.
                                </p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='vanish-strip'>
            </div>

            <div className='apo-model-section' id='apomodel'>
                <div className='apo-model-info-row' ref={ApoModelItem0}> 
                    <div className='apo-model-info-title'>
                        <div className='apo-model-info-inner'>
                            <h1 className='apo-model-item-title-1'>The APO</h1>
                            <h1 className='apo-model-item-title-2'>Model</h1>
                        </div>
                    </div>
                    <div className='apo-model-item'>
                        <div className='apo-model-item-inner'>
                            <div className='yellow-indicator ml-10'>
                                <p className='apo-model-item-info'>Alternative Public Offering (APO) is a combined Alternative Reverse Takeover Transaction (Going Public Event) with a simultaneous PIPE (Private Investment in Public Equity) Financing Event.
                                </p>
                            </div>
                            <div className="yellow-indicator ml-5">
                                <p className='apo-model-item-info'>Private Investment in Public Equity (PIPE) is a private investment in an existing or soon to be public entity. By utilizing a PIPE model our clients secure capital at a valuation that is an arbitrage in price between a private and public valuation such that dilution is minimized. This buying method is a practice of investment firms, mutual funds, and other large accredited investors.
                                </p>
                            </div>
                            <div className="yellow-indicator ml-5">
                                <p className='apo-model-item-info '>In the APO process, the client is provided capital via the PIPE and in turn receives the necessary number of shareholders to allow the listing on a US stock exchange or quotation platform immediately after the financing. As a condition to receiving offering proceeds the client must agree to go public immediately following the closing of the PIPE financing.
                                </p>
                            </div>
                            <div className="yellow-indicator ml-5">
                                <p className='apo-model-item-info'>The PIPE is done in concert with a FINRA licensed investment bank, such as Roadmap Advisors, who acts as placement agent for the financing transaction. The engaged investment bank will in turn act as placement agent or underwriter for subsequent financings as well as coordinate research and analyst coverage once the client is publicly traded.
                                </p>
                            </div>
                            <div className="yellow-indicator ml-5">
                                <p className='apo-model-item-info'>The net result for the private company is similar to a company completing an IPO, in that upon the completion of the transaction it is a publicly listed company with newly raised equity capital, institutional investor support and investment bank sponsorship.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='advantages-info-row' ref={ApoModelItem1}>
                    <h1 className="advantages-info-title-1">Highlights and advantages of</h1>
                    <h1 className="advantages-info-title-2">APO services include:</h1>
                    <ul className="advantages-info-list">
                        <li className="advantages-list-style">
                            <p className="advantages-list-text">Becoming a public company and raising capital in one transaction.</p>
                        </li >
                        <li className="advantages-list-style">
                            <p className="advantages-list-text">
                                Regulatory filings occur after the financing and going public event are completed, thus avoiding IPO-related risks.
                            </p>
                        </li>
                        <li className="advantages-list-style">
                            <p className="advantages-list-text">
                            Investment bank acts as placement agent, bringing post-closing market sponsorship.
                            </p>
                        </li>
                        <li className="advantages-list-style">
                            <p className="advantages-list-text">
                            Market validation through institutional financing.
                            </p>
                        </li>
                        <li className="advantages-list-style">
                            <p className="advantages-list-text">
                            Much faster and less risk than an IPO.
                            </p>
                        </li>
                        <li className="advantages-list-style">
                            <p className="advantages-list-text">
                            Higher valuations than raising money as a private company.
                            </p>
                        </li>
                        <li className="advantages-list-style">
                            <p className="advantages-list-text">
                            Flexibility in amount of capital that is raised, with a long-term process and strategy (including post-closing) to minimize issuer dilution.
                            </p>
                        </li>
                    </ul>
                </div> 
                <img src={RArrow} ref={ApoModelRightArrow} className='advantages-info-rarrow' onClick={(_) => apoModelSelector(1)} alt='right arrow' />
                <img src={LArrow} ref={ApoModelLeftArrow} className='advantages-info-larrow' onClick={(_) => apoModelSelector(0)} alt='left arrow'/>
            </div>
            
            <div className="header-section" id='mergeracquisitions'>
                <h1 className='header-section-header-primary'>Merger &amp;</h1>
                <h1 className='header-section-second-header'>Acquisitions</h1>
                <div className="yellow-indicator">
                    <p className='header-section-info'>All good mergers and acquisitions start with the right target and a good plan. Our experienced market specialists will help you identify and strategize to make the best business decisions.</p>
                </div>
            </div>
            <img src={Separator} alt="" className="separator-section screen-75"/>   
            <div id="mergeAcqCarousel" className='merger-acquisitions-info-row under-separator'>
                <div className='merger-acquisitions-inner'>
                    <div className='merger-acquisitions-item' ref={MergerAcqItem0}>
                        <div>
                            <span className='merger-acquisitions-item-title-1'>Acquisition </span>
                            <span className='merger-acquisitions-item-title-2'>Criteria</span>
                        </div>
                        <p className='merger-acquisitions-item-info'>Our experienced market professionals are always ready to help you make the right choices for your mergers and acquisitions. We can provide third-party valuation services and work in an advisory capacity to keep you informed about your options going forwards. What is good to acquire now? What should you prepare to acquire in the future? We stand ready to meet the unique requirements of your business dealings in the present and onwards into the future.
                        </p>
                    </div>
                    <div className='merger-acquisitions-item' ref={MergerAcqItem1}>
                        <div>
                            <span className='merger-acquisitions-item-title-1'>Market </span>
                            <span className='merger-acquisitions-item-title-2'>Guidance</span>
                        </div>
                        <p className='merger-acquisitions-item-info'>We will serve in an advisory capacity to help your company prosper in its home market or expand to new ones. Whether you’re trying to switch management, build new partnerships in a region, set up new trade venues, or even advice on developing new product lines and services. Your success in the market is our top priority.
                        </p>
                    </div>
                    <div className='merger-acquisitions-item' ref={MergerAcqItem2}>
                        <div>
                            <span className='merger-acquisitions-item-title-1'>Funding and </span>
                            <span className='merger-acquisitions-item-title-2'>Finance</span>
                        </div>
                        <p className='merger-acquisitions-item-info'>Funding and Finance for all your non-capital business requirements. This service can include funding for Mergers/Acquisitions, Business-To-Business transactions, and other business exchanges that can go up to the millions of dollars in value.
                        </p>
                    </div>
                    <div className='merger-acquisitions-item' ref={MergerAcqItem3}>
                        <div>
                            <span className="merger-acquisitions-item-title-1">Deal-</span>
                            <span className='merger-acquisitions-item-title-2'>Making</span>
                        </div>
                        <p className='merger-acquisitions-item-info'>Negotiation can make or break a deal, and whether you’re partnering up or ironing out the details of that latest merger we can help you see the deal through and will serve your interests like one of your own employees.
                        </p>
                    </div>
                    
                    <img src={LArrowAlt} ref={MergerAcqRightArrow} className='merger-acquisitions-left-arrow' onClick={(_) => {mergeAcqSelector(Math.abs((mergeAcqSelect-1))%2, 'left')}} alt='right arrow'/>
                    <img src={RArrowAlt} ref={MergerAcqLeftArrow} className='merger-acquisitions-right-arrow' onClick={(_) => {mergeAcqSelector((mergeAcqSelect+1)%2, 'right')}} alt='left arrow'/>
                    
                    <div className="merger-acquisitions-indicators" ref={MergerAcqItemPanel}>
                        {
                            mergeAcqSelect === 0 &&
                            <p className="circle-black" onClick={(_) => {mergeAcqSelector(0, 'left')}}></p>
                        }
                        {
                            mergeAcqSelect === 0 &&
                            <p className="circle-gray" onClick={(_) => {mergeAcqSelector(1, 'right')}}></p>
                        }
                        {
                            mergeAcqSelect === 1 &&
                            <p className="circle-gray" onClick={(_) => {mergeAcqSelector(0, 'left')}}></p>
                        }
                        {
                            mergeAcqSelect === 1 &&
                            <p className="circle-black" onClick={(_) => {mergeAcqSelector(1, 'right')}}></p>
                        }
                    </div>
                </div>

            </div>
            <div className="info-section investor-relation-services-row" id='investorrelationservices'>
                <div className="investor-relation-services-image">
                    <div className="investor-relation-services-inner">
                        <h1 className='investor-relation-header'>Investor</h1>
                        <h1 className='investor-relation-header-2 '>Relations Services</h1>
                        <div className="yellow-indicator">
                            <p className='header-section-info'>Investor relations (IR) refers to the function within a public company that is responsible for managing and communicating information to the public pertaining to the company's operations, managerial organization, and financial standing.</p>
                        </div>
                    </div>
                </div>
            </div>
            <img src={Separator} alt="" className="separator-section screen-65"/>   
            <div className="info-section public-manage-row under-separator" >
                <p className = "public-manage-info" data-aos="zoom-in" data-aos-duration="1500">
                    Public companies manage their investor relations function either through an in-house IR department or by outsourcing to an external investor relations firm that specializes in these activities.
                </p>
                <p className = "public-manage-info" data-aos="zoom-in" data-aos-duration="1600">
                    The IR team is responsible for maintaining the company’s most up-to-date information regarding its products and services. It also maintains the latest information about the company's operational and financial performance in its quarterly and annual reports, as well as its performance in the securities markets.
                </p>
                <p className = "public-manage-info" data-aos="zoom-in" data-aos-duration="1700">
                    IR Teams integrate finance, communication, marketing and securities law compliance to enable the most effective two-way communication between a company, the financial community, and other constituencies, which ultimately contributes to a company's securities achieving fair valuation.
                </p>
                <p className = "public-manage-info" data-aos="zoom-in" data-aos-duration="2000">
                    ACG investors relations service, provide the client with the whole toolbox, training, advise and interrelations, necessary for the setup of the correct tailor-made IR strategy.
                </p>
            </div>
            <div className="info-section we-help-section">
                <div className="we-help-you">
                    <h1 className="we-help-you-tittle-1">We help</h1>
                    <h1 className="we-help-you-tittle-2">you on:</h1>
                    <div className="we-help-you-inner">
                        <h3 className="help-you-inner-subheader">Market Relations:</h3>
                        <p className="help-you-inner-info">Running a public company can be another business in of itself. Not only do you have to worry about pleasing your clients and customers, but investors and the markets they’re a part of will form an entirely new facet of your business that needs to be given the same treatment. We can provide consultation services that will advise you and guide you along.</p>
                    
                        <h3 className="help-you-inner-subheader">IR Readiness Services:</h3>
                        <p className="help-you-inner-info">We will help your company create and run its very own IR department so that you can become more independent with your IR practices.</p>

                        <h3 className="help-you-inner-subheader">PR/IR Strategies:</h3>
                        <p className="help-you-inner-info">Whether you want to reach new clients or announce a special product, we can provide PR/IR Strategies in the role of a consultant and even finance these efforts, whether you’re trying to spread brochures or make a TV commercial.</p>
                    </div>
                </div>
            </div>
            <img src={Separator} alt="" className="separator-section screen-50"/>  
            <div className="info-section get-in-touch under-separator" id='getintouch'>
                <h3 className="get-in-touch-header">Get in touch</h3>
                <div className="touch-form">
                    <div className="touch-form-left">
                        <input type="text" placeholder="Name" className='input-custom' name="contact_name" ref={ContactName} onChange={changeName}/>
                        <input type="text" placeholder="Email" className='input-custom' name="email" ref={ContactEmail} onChange={changeEmail}/>
                    </div>
                    <div className="touch-form-right">
                        <textarea rows="3" cols="50"  placeholder="Message" className="input-custom-area" name="msg" ref={ContactMSG} onChange={changeMSG}/>
                    </div>
                </div>
                <p className='form-error msg-hidden' ref={ErrorMSG}>Valid</p>
                <button className="submit-button btn btn-white btn-animated" onClick={(_) => {submitContactUsMSG()}}>
                    Submit
                </button>
            </div>
            <div className='footer'>
                <div className='footer-info'>
                    <img src={AncorpLogoIcon} alt="" className='footer-logo'/>
                    <div className='direction-container'>
                        <img src={LocationIcon} alt="" className='location-icon'/>
                        <div>
                            <h4 className='location-label-footer'>Miami, USA</h4>
                            <p  className='direction-label-footer'>
                                804 South Douglas Rd 365.
                                <br/>
                                Coral Glabes, Fl. 33134. USA
                            </p>
                        </div>
                    </div>
                    <div className='direction-container'>
                        <img src={LocationIcon} alt="" className='location-icon'/>
                        <div>
                            <h4 className='location-label-footer'>Guadalajara, Mexico</h4>
                            <p className='direction-label-footer'>
                                Av. Américas No 1254,
                                <br/>
                                Country Club, Guadalajara, Jalisco, México
                            </p>
                        </div>
                    </div>
                    <div className='footer-list-section'> 
                        <ul>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Registration</p>
                            </li>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Investor</p>
                            </li>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Careers</p>
                            </li>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Privacy</p>
                            </li>
                            <li className='footer-list-style'>
                                <p className='footer-list-text'>Disclaimer</p>
                            </li>
                        </ul> 
                    </div>
                </div>
                <div className='footer-copy'>
                    <div className='footer-final-link'>
                        Copyright © Todos los derechos reservados.
                    </div>
                    <div className='footer-final-link'>
                        Aviso de privasidad 
                    </div>
                </div>
            </div>
        </Fragment>
    )
}